/**
 * Init module.
 *
 * @module components/init
 * @see module:main
 *
 * @file init.js
 *
 * Initializes the scripts
 *
 * Imports the javascript modules.
 * Creates new instances of the theme classes.
 *
 * @class      PeapodsWPCalendar
 * @package    peapods-wp-calendar
 * @subpackage peapods-wp-calendar/plugin
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

import Common from './common.js'
import { ImportData } from './import-data.js'
import { Controls } from './controls.js'
import { Calendars } from './calendars.js'
import { Output } from './output.js'
import { Key } from './key.js'
import { Widget } from './widget.js'
import { Flickity } from './flickity.js'

export class Init {
  constructor() {

    // Initialize common variables
    Common.init()

    // Quit if no calendar on page
    if (!Common.calendarID) return

    // Output initialisation message
    if (Common.consoleLogging) console.log('Initializing Peapods WP Calendar')

    // Import the calendar data
    new ImportData(this.run, this.error)
  }

  run() {

    // Handle the WP admin dashboard widget
    new Widget

    // Build and output the html
    new Calendars
    new Controls
    new Output
    new Key

    // Initiate Flickity
    new Flickity

  }

  error(errorMessage) {

    // TODO: Add user-friendly error message
    console.log(errorMessage)
  }
}