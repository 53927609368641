/**
 * Import Data module.
 *
 * @module components/ImportData
 *
 * @file import-data.js
 *
 * Import Data Functionality
 *
 *
 * @class      ImportData
 * @package    peapods-wp-calendar
 * @subpackage peapods-wp-calendar/plugin
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

import Common from './common.js'
import $ from 'jquery'

export class ImportData {
  constructor(callbackSuccess, callbackError) {

    // Store the callback functions
    this.callbackSuccess = callbackSuccess
    this.callbackError = callbackError

    // Initiate the data import
    this.import()
  }

  import() {

    // Request the calendar data via AJAX
    $.ajax({
      method: 'POST',
      url: Common.calendarAJAXLink,
      data: (JSON.stringify({ id: Common.calendarID }))
    })
      .done((response) => {

        // Check for error in response
        if (response.startsWith('Error')) {
          this.error(response)
          return
        }

        this.success(response)
      })
      .fail((jqXHR, response) => {

        this.error('Error: ' + response)
      })
  }

  success(response) {

    Common.calendarData = JSON.parse(response)

    this.callbackSuccess()
  }

  error(response) {

    this.callbackError(response)
  }
}