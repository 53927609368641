/**
 * Key module.
 *
 * @module components/Key
 *
 * @file Key.js
 *
 * Key Functionality
 *
 *
 * @class      Key
 * @package    peapods-wp-calendar
 * @subpackage peapods-wp-calendar/plugin
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

import Common from './common.js'

export class Key {
  constructor() {

    this.key()
  }

  key() {
    Common.$calendar.append(this.html())
  }

  html() {
    return `
    <div class="availability-key">
        <span class="availability-key-available">
        </span>
        <span class="availability-key-text">Available
        </span>
        <span class="availability-key-pending">
        </span>
        <span class="availability-key-text">Pending
        </span>
        <span class="availability-key-booked">
        </span>
        <span class="availability-key-text">Booked
        </span>
    </div>
    `
  }
}