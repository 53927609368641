/**
 * Common module.
 *
 * @module components/common
 * @see module:components/init
 *
 * @file common.js
 *
 * Provides common properties utilized across multiple classes.
 *
 * @class      Common
 * @package    peapods-wp-calendar
 * @subpackage peapods-wp-calendar/plugin
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 *
 */

import $ from 'jquery'

export default class Common {
  static init() {

    // Constants - plugin behaviour
    this.consoleLogging = true
    this.dateNow = new Date
    this.minCalendars = 24

    // API properties
    this.calendarAJAXLink = (window.location.hostname.includes('local.peapods.co.uk')) ?
      'https://local.peapods.co.uk/wellsguide/peapods-wp-calendar/api.php' :
      'https://wellsguide.com/peapods-wp-calendar/api.php'

    // Plugin properties
    this.calendarData = {}
    this.calendars = []

    // Cache DOM elements
    this.$calendar = $('#pp-wp-calendar')
    this.calendarID = this.$calendar.data('id')

    // Data values
    this.months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
    this.weekdays = ['M', 'T', 'W', 'T', 'F', 'S', 'S']
    this.weekdaysFull = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday'
    ]

  }
}