/**
 * Helpers module.
 *
 * @module components/Helpers
 *
 * @file Helpers.js
 *
 * Helpers Functionality
 *
 *
 * @class      Helpers
 * @package    peapods-wp-calendar
 * @subpackage peapods-wp-calendar/plugin
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

import Common from './common.js'

export class CalendarMonth {
  constructor() {

    this.year
    this.month
    this.html
    this.offset
    this.daysInMonth
    this.numRows
    this.dateNow = new Date().setHours(0, 0, 0, 0)
  }

  get(year, month) {

    this.year = year
    this.month = month

    this.offset = new Date(year, month - 1).getDay()
    this.offset = (!this.offset) ? 6 : this.offset - 1
    this.daysInMonth = new Date(year, month, 0).getDate()
    // this.numRows = Math.floor((this.daysInMonth + this.offset - 1) / 7);
    this.numRows = 5

    this.html = `
    <table>
      ${this.tableHeader()}
      ${this.tableBody()}
    </table>
    `

    return this.html
  }

  tableHeader() {
    return `
    <thead>
      ${this.monthHeader()}
      ${this.weekdayHeader()}
    </thead>
    `
  }

  monthHeader() {
    return `
      <tr class="month">
        <th colspan="7">${Common.months[this.month - 1]} ${this.year}</th>
      </tr>
    `
  }

  weekdayHeader() {
    let weekdays = '<tr class="weekdays">'

    Common.weekdays.forEach((weekday, idx) => {
      weekdays += `<th scope="col" aria-label="${Common.weekdaysFull[idx]}">${weekday}</th>`
    })

    weekdays += '</tr>'
    return weekdays
  }

  tableBody() {

    let body = '<tbody>'

    for (var r = 0; r <= this.numRows; r++) body += this.tableRow(r)

    body += '</tbody>'
    return body
  }

  tableRow(r) {
    let row = '<tr>'

    for (var c = 0; c < 7; c++) row += this.tableCell(r, c)

    row += '</tr>'
    return row
  }

  tableCell(r, c) {
    let cellNum = (r * 7) + c
    let cell = '<td class="empty">&nbsp;'

    if ((cellNum >= this.offset) && (cellNum < this.offset + this.daysInMonth)) {
      cell = this.cellContent(cellNum - this.offset + 1)
    }

    cell += '</td>'
    return cell
  }

  cellContent(dayNum) {
    let date = this.year + '-' + this.twoDigits(this.month) + '-' + this.twoDigits(dayNum)

    let cellClass = Common.calendarData[date]
    cellClass = (cellClass) ? cellClass : 'available'

    if (this.dateNow > new Date(this.year, this.month - 1, dayNum)) {
      cellClass = 'past'
    };

    // Capitalize first letter & convert transition days
    let label = cellClass.charAt(0).toUpperCase() + cellClass.slice(1)
    label = (label.includes('-')) ? 'Changeover day' : label

    return `<td class="${cellClass}" aria-label="${label}">${dayNum}`
  }

  twoDigits(input) {
    return ('0' + input).slice(-2)
  }

}