/**
 * Helpers module.
 *
 * @module components/Helpers
 *
 * @file Helpers.js
 *
 * Helpers Functionality
 *
 *
 * @class      Helpers
 * @package    peapods-wp-calendar
 * @subpackage peapods-wp-calendar/plugin
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

import Common from './common.js'
import { CalendarMonth } from './calendar-month.js'

export class Calendars {
  constructor() {

    // Clear the calendar html array
    Common.calendars = []

    // Get new instance of the calendar html constructor class
    this.calendarMonth = new CalendarMonth()

    this.getCalendars()
  }

  getCalendars() {

    let calendar
    let date = new Date
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let count = 0
    let lastKey = Object.keys(Common.calendarData).pop()
    let lastDate = new Date(lastKey)
    lastDate.setMonth(lastDate.getMonth() + 1)

    while ((count < Common.minCalendars) || (date < lastDate)) {

      year = date.getFullYear()
      month = date.getMonth() + 1

      calendar = {
        year: year,
        month: month,
        html: this.calendarMonth.get(year, month)
      }

      Common.calendars.push(calendar)

      date = this.addMonths(date, 1)
      count++
    }
  }

  addMonths(date, months) {
    var d = date.getDate()
    date.setMonth(date.getMonth() + +months)
    if (date.getDate() != d) {
      date.setDate(0)
    }
    return date
  }
}