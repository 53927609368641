/**
 * Key module.
 *
 * @module components/Key
 *
 * @file Key.js
 *
 * Key Functionality
 *
 *
 * @class      Key
 * @package    peapods-wp-calendar
 * @subpackage peapods-wp-calendar/plugin
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

import Common from './common.js'
import $ from 'jquery'

export class Controls {
  constructor() {

    this.$select

    this.output()
    this.select()
    this.events()

    this.$selectCover = $('.select-cover')
    Common.controls = this

    this.setSelectCover()
  }

  events() {

    $('.calendar-controls .previous').on('click', () => this.previous())
    $('.calendar-controls .next').on('click', () => this.next())
    $('.calendar-controls select').on('change', () => this.changeSelect())

    this.tabDetect()
  }

  tabDetect() {

    // Only set up once, constructor may be called multiple times
    if (Common.tabDetectDone !== true) {

      Common.tabDetectDone = true

      // Let the document know when the mouse is being used
      document.body.addEventListener('mousedown', function () {
        document.body.classList.add('using-mouse')
      })

      // Re-enable focus styling when Tab is pressed
      document.body.addEventListener('keydown', function (event) {
        if (event.key === 'Tab') {
          document.body.classList.remove('using-mouse')
        }
      })
    }
  }

  previous() {
    Common.$flickity.flickity('previous')
  }

  next() {
    Common.$flickity.flickity('next')
  }

  output() {
    Common.$calendar.append(this.html())
  }

  html() {
    return `
    <div class="calendar-controls">
      <button class="previous" aria-label='Previous'></button>
      <div class="select"><div class="select-cover"></div></div>
      <button class="next" aria-label='Next'></button>
    </div>
    `
  }

  select() {

    let year
    let $optgroup
    this.$select = $('<select name="month" id="month-select" aria-label="Month and year">')

    Common.calendars.forEach((calendar, idx) => {

      if (year !== calendar.year) {

        if ($optgroup) this.$select.append($optgroup)

        year = calendar.year
        $optgroup = $('<optgroup label="' + calendar.year + '">')
      }

      $optgroup.append($('<option>', {
        value: idx,
        text: Common.months[calendar.month - 1]
      }))
    })

    this.$select.append($optgroup)

    $('.calendar-controls .select').prepend(this.$select)
  }

  changeSelect() {

    let selected = $('#month-select :selected').val()
    this.setSelectCover(selected)

    let groupSize = $('#pp-wp-calendar .is-selected').length
    let value = Math.floor(selected / groupSize)

    Common.$flickity.flickity('select', value)
  }

  // Called from the flickity event handler
  setSelect(index) {

    // Get the currently selected value of the select control
    let currSelected = $('#month-select :selected').val()
    let groupSize = $('#pp-wp-calendar .is-selected').length
    let currIndex = Math.floor(currSelected / groupSize)

    // Only set select and cover if new value is outside existing group
    if (currIndex !== index) {

      let value = index * groupSize

      this.setSelectCover(value)
      this.setSelectOption(value)
    }
  }

  setSelectCover(value = 0) {

    let month = Common.months[Common.calendars[value].month - 1]
    let year = Common.calendars[value].year

    this.$selectCover.text(month + ' ' + year)
  }

  setSelectOption(value) {

    $('.calendar-controls select').val(value)
  }

}