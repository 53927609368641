/**
 * Widget module.
 *
 * @module components/Widget
 *
 * @file widget.js
 *
 * Widget Functionality
 *
 *
 * @class      Widget
 * @package    peapods-wp-calendar
 * @subpackage peapods-wp-calendar/plugin
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

import Common from './common.js'
import { Controls } from './controls.js'
import { Calendars } from './calendars.js'
import { Output } from './output.js'
import { Key } from './key.js'
import { Flickity } from './flickity.js'
import $ from 'jquery'

export class Widget {
  constructor() {

    // Exit if no admin element on page
    if (!$('#calendar-admin-form').length) return

    // Increase the number of calendars that can be viewed
    Common.minCalendars = 36

    // Time in ms that a message is displayed
    this.messageDelay = 5000

    // Store this class instance
    Common.widget = this

    this.events()
  }

  events() {

    $('#calendar-admin-form').submit(() => this.processForm())
  }

  processForm() {

    event.preventDefault()

    let data = this.getFormData()

    if (!this.validateFormData(data)) return

    this.userMessage('Updating calendar...')

    this.update(data)
  }

  update(data) {

    data['request'] = 'update'

    // Send the calendar data via AJAX
    $.ajax({
      method: 'POST',
      url: Common.calendarAJAXLink,
      data: (JSON.stringify(data))
    })
      .done((response) => {

        // Check for error in response
        if (response.startsWith('Error')) {
          this.error(response)
          return
        }

        this.success(response)
      })
      .fail((jqXHR, response) => {

        this.error('Error: ' + response)
      })
  }

  success(response) {

    this.userMessage('Calendar updated.', this.messageDelay)

    // Store the new calendar data
    Common.calendarData = JSON.parse(response)

    // Update calendar html
    new Calendars

    // Get the currently viewed calendar index
    let flkty = Common.$flickity.data('flickity')
    let slideIdx = flkty.selectedIndex

    // Clear the old calendar from the DOM
    Common.$calendar.empty()

    // Build and output the html
    new Controls
    new Output
    new Key

    // Initiate Flickity
    new Flickity

    // Set Flickity to previously viewed calendar
    Common.$flickity.flickity('select', slideIdx, false, true)
  }

  error(response) {

    this.userMessage('Error updating calendar. Please try again later. If the error persists, please contact the website administrator.')

    console.log(response)
  }

  getFormData() {

    let formData = $('#calendar-admin-form').serializeArray()
    let data = {}

    formData.forEach((field) => {
      data[field.name] = field.value
    })

    return data
  }

  validateFormData(data) {

    let start = new Date(data.start)
    let end = new Date(data.end)

    if (start.toString() === 'Invalid Date') {
      this.userMessage('Error: Start date is invalid.', this.messageDelay)
      return false
    }

    if (end.toString() === 'Invalid Date') {
      this.userMessage('Error: End date is invalid.')
      return false
    }

    if (start.getTime() >= end.getTime()) {
      this.userMessage('Error: End date must be later than start date.', this.messageDelay)
      return false
    }

    let plusThreeYears = new Date()
    plusThreeYears.setFullYear(plusThreeYears.getFullYear() + 3)

    if (end.getTime() >= plusThreeYears.getTime()) {
      this.userMessage('Error: End date cannot be more than 3 years in the future.', this.messageDelay)
      return false
    }

    let now = new Date()
    now.setDate(now.getDate() - 1)

    if (start.getTime() < now.getTime()) {
      this.userMessage('Error: Start date cannot be in the past.', this.messageDelay)
      return false
    }

    if ((data.status !== 'available') && (data.status !== 'pending') && (data.status !== 'booked')) {
      this.userMessage('Error: Booking status is invalid.', this.messageDelay)
      return false
    }

    return true
  }

  userMessage(message, delay = undefined) {
    $('#calendar-admin-message').text(message)

    if (delay) {

      window.setTimeout(() => {
        $('#calendar-admin-message').text('')
      }, delay)
    }
  }
}