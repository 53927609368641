/**
 * Peapods WP Calendar javascript module.
 *
 * @module main
 *
 * @file main.js
 *
 * Initiates the necessary javascript
 *
 * Imports the Init module and runs the plugin.
 *
 * @class      PeapodsWPCalendar
 * @package    peapods-wp-calendar
 * @subpackage peapods-wp-calendar/plugin
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */
import { Init } from './components/init.js';

class PeapodsWPCalendar {
  constructor() {
    let init = new Init();
  }
}
document.addEventListener('DOMContentLoaded', function(event) {
  const peapodsWPCalendar = new PeapodsWPCalendar();
});