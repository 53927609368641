/**
 * Output module.
 *
 * @module components/Output
 *
 * @file Output.js
 *
 * Output Functionality
 *
 *
 * @class      Output
 * @package    peapods-wp-calendar
 * @subpackage peapods-wp-calendar/plugin
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

import Common from './common.js'
import $ from 'jquery'

export class Output {
  constructor() {

    this.output()
  }

  output() {

    let $carousel = $('<div class="calendar-carousel">')

    Common.calendars.forEach((calendar) => {

      $carousel.append(
        '<div class="calendar-carousel-cell">' + calendar.html + '</div>'
      )

    })

    Common.$calendar.append($carousel)
  }
}