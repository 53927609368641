/**
 * Flickity module.
 *
 * @module components/Flickity
 *
 * @file Flickity.js
 *
 * Flickity Functionality
 *
 *
 * @class      OutFlickityput
 * @package    peapods-wp-calendar
 * @subpackage peapods-wp-calendar/plugin
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

import Common from './common.js'
import { Controls } from './controls.js'
import $ from 'jquery'

export class Flickity {
  constructor() {

    this.flickity()
    this.events()
  }

  flickity() {

    Common.$flickity = $('.calendar-carousel').flickity({
      // options
      selectedAttraction: 0.05,
      friction: 0.5,
      contain: true,
      groupCells: true,
      prevNextButtons: false,
      pageDots: false
    })
  }

  events() {

    Common.$flickity.on(
      'change.flickity', (event, index) => this.change(index)
    )
  }

  change(index) {

    // Update the selection control
    Common.controls.setSelect(index)
  }
}